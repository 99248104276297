import React, { useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { RE_EXAM_REPORT_DETAILED } from "../../../../utils/Examination.apiConst";
import { useEffect } from "react";
import { Http } from "../../../../Services/Services";
import { useRef } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import { useReactToPrint } from "react-to-print";
import "../../Reports/template.css";

import Loader from "../../../../Components/Loader/Loader";
import { sessionOpt } from "../../../../Data/jsonData/Academics/Academics";

const calculateGrade = (p) => {
  if (p >= 85) return "O+";
  if (p >= 70 && p <= 84.99) return "O";
  if (p >= 60 && p <= 69.99) return "A";
  if (p >= 55 && p <= 59.99) return "B+";
  if (p >= 48 && p <= 54.99) return "B";
  if (p >= 36 && p <= 47.99) return "C";
  if (p < 36) return "D";
};

const calculateGradePoint = (p) => {
  return parseFloat(p.toFixed(2) * 10).toFixed(2);
};

const facultyData = [
  {
    id: 1111000,
    name: "FACULTY OF ENGINEERING AND TECHNOLOGY",
    college_type_id: "ENG",
    status: "ACTIVE",
    colloge_name: "Shree Swaminarayan College Engineering and Technology,KALOL",
  },
  {
    id: 1111001,
    name: "FACULTY OF AYURVEDA",
    college_type_id: "AYU",
    status: "ACTIVE",
    colloge_name: "Shree Swaminarayan Ayurvedic College,Kalol",
  },
  {
    id: 1111002,
    name: "FACULTY OF LAW",
    college_type_id: "LAW",
    status: "ACTIVE",
    colloge_name: "Shree Swaminarayan Law College, Kalol",
  },
  {
    id: 1111003,
    name: "FACULTY OF NURSING",
    college_type_id: "NUR",
    status: "ACTIVE",
    colloge_name: "Shree Swaminarayan  College of Nursing, Kalol",
  },
  {
    id: 1111004,
    name: "FACULTY OF ARTS AND HUMANITIES ",
    college_type_id: "NUR",
    status: "ACTIVE",
    colloge_name: "Shree Swaminarayan Arts  College, Kalol",
  },
  {
    id: 1111005,
    name: "FACULTY OF COMMERCE AND MANAGEMENT",
    college_type_id: "COM",
    status: "ACTIVE",
    colloge_name: "FACULTY OF COMMERCE AND MANAGEMENT,KALOL",
  },
  {
    id: 1111010,
    name: "Faculty of Education",
    college_type_id: "EDU",
    status: "ACTIVE",
    colloge_name: "Shree Swaminarayan B.Ed College, Kalol",
    program_name: "Bachelor of Education ",
  },
  {
    id: 1111011,
    name: "FACULTY OF SCIENCE",
    college_type_id: "SCI",
    status: "ACTIVE",
    colloge_name: "Shree Swaminarayan  Science College, Kalol",

    program_name: "Bachelor of Science ",
  },
  {
    id: 1111007,
    name: "FACULTY OF HOMEOPATHY",
    college_type_id: "HOMEOPATHY",
    status: "ACTIVE",
    colloge_name: "Shree Swaminarayan Homoeopathy College, Kalol",
  },
];

var months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const today = new Date();

// Get day, month, and year components
const day = today.getDate().toString().padStart(2, "0");
const month = (today.getMonth() + 1).toString().padStart(2, "0");
const year = today.getFullYear();
const formattedDate = `${day}-${month}-${year}`;

const MarksSheetIndividual = ({
  examData,
  examResults,
  prevExamData,
  setLoading,
}) => {
  const [cnt, setCnt] = useState(2);
  const totalcredits = useMemo(() => {
    return examResults?.reduce((total, i) => total + Number(i?.credit || 0), 0);
  }, [examResults]);
  const totalsum_max = useMemo(() => {
    return examResults?.reduce(
      (total, i) => total + Number(i?.max_marks || 0),
      0
    );
  }, [examResults]);

  const totalsum_min = useMemo(() => {
    return examResults?.reduce(
      (total, i) => total + Number(i?.min_marks || 0),
      0
    );
  }, [examResults]);

  const totalsum_obt = useMemo(() => {
    return examResults?.reduce(
      (total, i) => total + Number(i?.marks || 0) + Number(i?.grace_marks || 0),
      0
    );
  }, [examResults]);

  const totalabsent = useMemo(() => {
    return examResults?.reduce((total, i) => total + Number(i?.absent || 0), 0);
  }, [examResults]);

  const combinedData = useMemo(() => {
    return Object.values(
      examResults.reduce((acc, curr) => {
        const course_name = curr.course_name;
        if (!acc[course_name]) {
          acc[course_name] = { ...curr };
          acc[course_name].max_marks = Number(acc[course_name].max_marks);
          acc[course_name].min_marks = Number(acc[course_name].min_marks);
        } else {
          acc[course_name].marks += curr.marks;
          acc[course_name].max_marks += curr.max_marks;
          acc[course_name].min_marks += curr.min_marks;
          acc[course_name].grace_marks += Number(curr.grace_marks || 0);
          acc[course_name].credits += Number(curr.credits || 0);
        }
        return acc;
      }, {})
    );
  }, [examResults]);

  const combinedDataPrev = useMemo(() => {
    const result2 =
      Object.values(
        prevExamData?.reduce((acc, curr) => {
          const subject_name = curr?.subject;
          if (!acc[subject_name]) {
            acc[subject_name] = { ...curr };
            acc[subject_name].i_max = Number(acc[subject_name].i_max);
            acc[subject_name].e_max = Number(acc[subject_name].e_max);
            acc[subject_name].e_min = Number(acc[subject_name].e_min);
            acc[subject_name].i_min = Number(acc[subject_name].i_min);
            acc[subject_name].e_marks = Number(acc[subject_name].e_marks);
            acc[subject_name].i_marks = Number(acc[subject_name].i_marks);
          } else {
            acc[subject_name].e_marks += Number(curr.e_marks);
            acc[subject_name].e_max += Number(curr.e_max);
            acc[subject_name].e_min += Number(curr.e_min);
            acc[subject_name].i_marks += Number(curr.i_marks);
            acc[subject_name].i_max += Number(curr.i_max);
            acc[subject_name].i_min += Number(curr.i_min);
            acc[subject_name].e_grace_marks += Number(curr.e_grace_marks);
          }
          return acc;
        }, {})
      ) || [];

    // if (prevExamData[0].student_id == "SUK210604HO002") console.log(result2);

    return result2;
  }, [prevExamData]);
  // if (prevExamData[0].student_id == "SUK210604HO002") console.log(combinedData);

  const checkCondition = (data) => {
    return Number(data)
      ? Number(data)
      : examData?.college == "FACULTY OF HOMOEOPATHY" ||
        examData?.college == "FACULTY OF AYURVEDA" ||
        examData?.college == "FACULTY OF NURSING"
      ? null
      : "-";
  };

  const [isPass, setIsPass] = useState(0);

  useEffect(() => {
    var flag = 0;
    for (const item of combinedData) {
      // console.log("result item", item);
      if (item.absent) {
        flag = 1;
        break;
      }
      if (item.min_marks > Number(item.grace_marks) + item.marks) {
        flag = 1;
        break;
      }
    }
    if (!flag) {
      setIsPass(1);
    } else {
      setIsPass(0);
    }
  }, [examResults]);

  return (
    <div className="col-md-12 bg2">
      {/* <img
                                    src='./ResultTemplate.jpg'
                                    alt="Your Image"
                                    className="background-image"
                                  /> */}
      <div className="res2">
        <table className="table table-bordered text-center result overlay-table">
          <thead className=" ">
            <tr>
              <th>Academic Year</th>
              <th>Month and year of Examination</th>
              <th>Year/Sem</th>
              <th>Enrollment Number</th>
            </tr>
            <tr>
              <td>
                {sessionOpt.find((s) => s?.id == examData?.session_id)?.name}{" "}
              </td>
              <td>
                {months[Number(examData?.month) - 1]}-{examData?.year || "2023"}
              </td>
              <td>
                {examData?.college_id == 1111001 ||
                examData?.college_id == 1111007
                  ? examData?.class
                  : examData?.semester}
              </td>
              <td>{examResults[0]?.student_id}</td>
            </tr>
            <tr>
              <th>Faculty Name</th>
              <th colSpan="2">College Name</th>
              <th> Attempts</th>
            </tr>
            <tr>
              <td>{examData?.college}</td>

              <td colSpan="2">
                {
                  facultyData.find((item) => item.id == examData?.college_id)
                    ?.colloge_name
                }
              </td>

              <th>
                {cnt}
                {cnt == 1 ? "st" : cnt == 2 ? "nd" : cnt == 3 ? "rd" : "th"}
              </th>
            </tr>

            {examResults[0]?.program != "DOCTORATE" ? (
              <>
                <tr>
                  <th>Program</th>

                  <td>{examData?.department}</td>
                  <th>Student Name</th>
                  <td>{examResults[0]?.name}</td>
                </tr>
              </>
            ) : null}
          </thead>
        </table>
        {/* <table className="table table-bordered result ">
          <thead>
            <tr>
              <th>Enrollment Number</th>
              <td>{examResults?.[0]?.student_id}</td>
              <th>Student Name </th>
              <td>{examResults?.[0]?.name}</td>
            </tr>
            <tr>
              <th>Faculty Name</th>
              <td>{examData?.college}</td>

              <th>Program</th>
              <td>{examData?.department}</td>
            </tr>
            <tr>
              <th>College Name</th>
              <td>
                {
                  facultyData.find((item) => item.id == examData?.college_id)
                    ?.colloge_name
                }
              </td>

              <th>
                {examData?.department == "B.H.M.S."
                  ? "Year"
                  : examData?.department == "B.Sc. Nursing"
                  ? "Year"
                  : examData?.department == "Post Basic B.Sc. Nursing"
                  ? "Year"
                  : "Semester"}
              </th>

              <td>
                {" "}
                {examData?.department == "B.H.M.S."
                  ? "1st Year"
                  : examData?.department == "B.Sc. Nursing"
                  ? "1st Year"
                  : examData?.department == "Post Basic B.Sc. Nursing"
                  ? "1st Year"
                  : "2"}{" "}
              </td>
            </tr>
            <tr>
              <th>No of Attempts</th>
              <th>
                {cnt}
                {cnt == 1 ? "st" : cnt == 2 ? "nd" : cnt == 3 ? "rd" : "th"}
              </th>
            </tr>
          </thead>
        </table> */}

        <table className="table text-center   marks table-bordered ">
          <thead>
            <tr>
              <th rowSpan="2" className="text-center">
                Subject Code
              </th>
              <th rowSpan="2" className="text-center">
                Subject Name
              </th>

              {totalcredits != 0 || totalcredits == null ? (
                <th rowSpan="2" className="text-center">
                  Credit
                </th>
              ) : (
                ""
              )}

              <th className="text-center" colSpan="3">
                {" "}
                {examData?.department == "B.H.M.S." ? (
                  "University Exam"
                ) : (
                  <span>
                    {" "}
                    University Exam <br /> Internal Exam
                  </span>
                )}
              </th>

              {totalcredits != 0 ? (
                <th rowSpan="2" className="text-center">
                  {" "}
                  Grade
                </th>
              ) : (
                ""
              )}
              {totalcredits != 0 ? (
                <th rowSpan="2" className="text-center">
                  {" "}
                  GP
                </th>
              ) : (
                ""
              )}
              {totalcredits != 0 ? (
                <th rowSpan="2" className="text-center">
                  {" "}
                  CGP
                </th>
              ) : (
                ""
              )}
              <th rowSpan="2" className="text-center">
                {" "}
                Result
              </th>
            </tr>
            <tr>
              <th className="text-center">Max</th>
              <th className="text-center">Min</th>
              <th className="text-center">Obtained</th>
            </tr>
          </thead>
          <tbody>
            {combinedData &&
              combinedData?.map((i, key) => (
                <tr key={key}>
                  <td className="text-center">{i?.sub_code}</td>
                  <td className="text-center">{i?.course_name}</td>
                  {totalcredits != 0 ? (
                    <td className="text-center">{i?.credit || 0} </td>
                  ) : (
                    ""
                  )}

                  <td className="text-center">
                    {i?.max_marks == 0 || null ? "-" : i?.max_marks}
                    <br />
                    {checkCondition(
                      combinedDataPrev?.find((s) => s.subject == i?.course_name)
                        ?.i_max
                    )}
                  </td>

                  <td className="text-center">
                    {i?.min_marks == 0 || null ? "-" : i?.min_marks}
                    <br />
                    {checkCondition(
                      combinedDataPrev?.find((s) => s.subject == i?.course_name)
                        ?.i_min
                    )}
                  </td>

                  <td className="text-center">
                    {i?.absent == 1 ? (
                      <span className="text-danger">
                        <b>A</b>
                      </span>
                    ) : i?.marks == 0 ? (
                      "-"
                    ) : i?.marks == null ? (
                      "-"
                    ) : i?.marks == "" ? (
                      "-"
                    ) : i?.grace_marks == null || i?.grace_marks == 0 ? (
                      i?.marks
                    ) : (
                      i?.marks + "+" + i?.grace_marks
                    )}{" "}
                    <br />
                    {checkCondition(
                      combinedDataPrev?.find((s) => s.subject == i?.course_name)
                        ?.i_marks
                    )}
                  </td>
                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {!i?.grace_marks
                        ? Number(i?.marks)
                        : Number(i?.marks) + Number(i?.grace_marks)}
                    </td>
                  ) : (
                    ""
                  )}
                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {!i?.grace_marks
                        ? calculateGrade(
                            ((Number(i?.marks) + Number(i?.grace_marks)) /
                              Number(i?.max_marks)) *
                              100
                          )
                        : calculateGrade(
                            ((Number(i?.marks) + Number(i?.grace_marks)) /
                              Number(i?.max_marks)) *
                              100
                          )}
                    </td>
                  ) : (
                    ""
                  )}

                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {!i?.grace_marks
                        ? calculateGradePoint(
                            (Number(i?.marks) + Number(i?.grace_marks)) /
                              Number(i?.max_marks)
                          )
                        : calculateGradePoint(
                            (Number(i?.marks) + Number(i?.grace_marks)) /
                              Number(i?.max_marks)
                          )}{" "}
                    </td>
                  ) : (
                    ""
                  )}

                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {i?.e_grace == null
                        ? calculateGradePoint(
                            ((Number(i?.marks) + Number(i?.grace_marks)) /
                              Number(i?.max_marks)) *
                              Number(i?.credits).toFixed(2)
                          )
                        : calculateGradePoint(
                            ((Number(i?.marks) + Number(i?.grace_marks)) /
                              Number(i?.max_marks)) *
                              Number(i?.credits).toFixed(2)
                          )}{" "}
                    </td>
                  ) : (
                    ""
                  )}

                  <td className="text-center">
                    {i?.data?.result}{" "}
                    {Number(i?.marks) + Number(i?.grace_marks) >=
                      i?.min_marks || i?.marks == null ? (
                      <p className="text-success">
                        <b> P </b>{" "}
                      </p>
                    ) : Number(i?.marks) + Number(i?.grace_marks) >=
                        i?.min_marks || i?.marks == null ? (
                      <p className="text-success">
                        <b> P </b>{" "}
                      </p>
                    ) : (
                      <p className="text-danger">
                        <b>F </b>
                      </p>
                    )}{" "}
                  </td>
                </tr>
              ))}
            {combinedDataPrev &&
              combinedDataPrev
                ?.filter(
                  (s) =>
                    s.subject !=
                    combinedData?.find((s1) => s1.course_name == s.subject)
                      ?.course_name
                )
                ?.map((i, key) => (
                  <tr key={key}>
                    <td className="text-center">{i?.sub_code}</td>
                    <td className="text-center">{i?.subject}</td>
                    {totalcredits != 0 ? (
                      <td className="text-center">{i?.credit} </td>
                    ) : (
                      ""
                    )}

                    <td className="text-center">
                      {checkCondition(i?.e_max)}
                      <br />
                      {checkCondition(i?.i_max)}
                    </td>

                    <td className="text-center">
                      {checkCondition(i?.e_min)}
                      <br />
                      {checkCondition(i?.i_min)}
                    </td>

                    <td className="text-center">
                      {examData?.department == "BAMS" ||
                      examData?.department == "B.H.M.S." ? (
                        "E"
                      ) : (
                        <>
                          {checkCondition(i?.e_marks) +
                          checkCondition(i?.i_marks)
                            ? "E"
                            : null}
                        </>
                      )}
                    </td>

                    <td className="text-center">
                      {Number(i?.i_marks) +
                        Number(i?.e_grace_marks) +
                        Number(i?.e_marks) >=
                        i?.i_min + i?.e_min || i?.marks == null ? (
                        <p className="text-success">
                          <b> E </b>{" "}
                        </p>
                      ) : (
                        <p className="text-danger">
                          <b>F </b>
                        </p>
                      )}{" "}
                    </td>
                  </tr>
                ))}

            <tr>
              <th className="text-center">Total</th>
              {totalcredits != 0 ? <th></th> : ""}
              {totalcredits != 0 ? (
                <td className="text-center"> {totalcredits} </td>
              ) : (
                <th></th>
              )}

              <td className="text-center">
                {checkCondition(
                  totalsum_max +
                    combinedDataPrev
                      ?.filter(
                        (s) =>
                          s.subject !=
                          combinedData?.find(
                            (s1) => s1.course_name == s.subject
                          )?.course_name
                      )
                      ?.reduce((acc, it) => acc + Number(it.e_max), 0)
                )}{" "}
                <br />
                {checkCondition(
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject ==
                        combinedData?.find((s1) => s1.course_name == s.subject)
                          ?.course_name
                    )
                    ?.reduce((acc, it) => acc + Number(it.i_max), 0) +
                    combinedDataPrev
                      ?.filter(
                        (s) =>
                          s.subject !=
                          combinedData?.find(
                            (s1) => s1.course_name == s.subject
                          )?.course_name
                      )
                      ?.reduce((acc, it) => acc + Number(it.i_max), 0)
                )}
              </td>

              <td className="text-center">
                {examData?.department == "B.H.M.S." ||
                examData?.department == "Bachelor of Education " ? (
                  <>
                    {checkCondition(
                      totalsum_min +
                        combinedDataPrev
                          ?.filter(
                            (s) =>
                              s.subject !=
                              combinedData?.find(
                                (s1) => s1.course_name == s.subject
                              )?.course_name
                          )
                          ?.reduce((acc, it) => acc + Number(it.e_min), 0)
                    )}
                    <br />
                    {checkCondition(
                      combinedDataPrev
                        ?.filter(
                          (s) =>
                            s.subject ==
                            combinedData?.find(
                              (s1) => s1.course_name == s.subject
                            )?.course_name
                        )
                        ?.reduce((acc, it) => acc + Number(it.i_min), 0) +
                        combinedDataPrev
                          ?.filter(
                            (s) =>
                              s.subject !=
                              combinedData?.find(
                                (s1) => s1.course_name == s.subject
                              )?.course_name
                          )
                          ?.reduce((acc, it) => acc + Number(it.i_min), 0)
                    )}
                  </>
                ) : examData?.department == "B.Sc. Nursing" ? (
                  " "
                ) : null}
              </td>
              <th className="text-center">
                {examData?.department == "B.Sc. Nursing" ||
                "Post Basic B.Sc. Nursing" ? (
                  <>
                    {totalsum_obt}
                    <br />
                    {checkCondition(
                      combinedDataPrev
                        ?.filter(
                          (s) =>
                            s.subject ===
                            combinedData?.find(
                              (s1) => s1.course_name === s.subject
                            )?.course_name
                        )
                        ?.reduce((acc, it) => acc + Number(it.i_marks), 0)
                    )}
                  </>
                ) : (
                  <>
                    {checkCondition(
                      totalsum_obt +
                        combinedDataPrev
                          ?.filter(
                            (s) =>
                              s.subject !==
                              combinedData?.find(
                                (s1) => s1.course_name === s.subject
                              )?.course_name
                          )
                          ?.reduce((acc, it) => acc + Number(it.e_marks), 0)
                    )}
                    <br />
                    {checkCondition(
                      combinedDataPrev
                        ?.filter(
                          (s) =>
                            s.subject ===
                            combinedData?.find(
                              (s1) => s1.course_name === s.subject
                            )?.course_name
                        )
                        ?.reduce((acc, it) => acc + Number(it.i_marks), 0) +
                        combinedDataPrev
                          ?.filter(
                            (s) =>
                              s.subject !==
                              combinedData?.find(
                                (s1) => s1.course_name === s.subject
                              )?.course_name
                          )
                          ?.reduce((acc, it) => acc + Number(it.i_marks), 0)
                    )}
                  </>
                )}
              </th>
              <td></td>
              {totalcredits != 0 ? <th colSpan={2}></th> : ""}

              {totalcredits != 0 ? (
                <th className="text-center">
                  {totalabsent >= 1
                    ? "-"
                    : examResults
                        ?.reduce(
                          (total, i) =>
                            i?.is_not_countable
                              ? total + 0
                              : total +
                                calculateGradePoint(
                                  (Number(i?.marks) + Number(i?.grace_marks)) /
                                    Number(i?.max_marks)
                                ) *
                                  Number(i?.credits),
                          0
                        )
                        .toFixed(2)}
                </th>
              ) : (
                ""
              )}
              {totalcredits != 0 ? <th></th> : ""}
            </tr>
            <tr className="text-center">
              <th className="text-center"> Result </th>
              <td colSpan={3} className="text-center">
                {totalabsent >= 1 ||
                examResults?.reduce(
                  (total, i) =>
                    total +
                    (calculateGradePoint(
                      (Number(i?.marks) + Number(i?.grace_marks)) /
                        Number(i?.max)
                    ) <
                      3.6),
                  0
                ) ||
                !isPass ? (
                  <span className="text-danger">
                    <b> FAIL </b>{" "}
                  </span>
                ) : (
                  <span className="text-success">
                    <b>PASS </b>
                  </span>
                )}
              </td>

              {totalcredits != 0 ? <th>SGPA</th> : <th></th>}
              {totalcredits != 0 ? (
                <td colSpan={4} className="text-center">
                  {totalabsent?.absent >= 1 ||
                  !isPass ||
                  examResults?.reduce(
                    (total, i) =>
                      i?.is_not_countable
                        ? total + 0
                        : total +
                          (calculateGradePoint(
                            (Number(i?.i_marks) +
                              Number(i?.e_marks) +
                              Number(i?.e_grace)) /
                              (Number(i?.e_max) + Number(i?.i_max))
                          ) <
                            3.6),
                    0
                  )
                    ? "-"
                    : totalabsent?.absent >= 1
                    ? "-"
                    : (
                        (examResults?.reduce(
                          (total, i) =>
                            i?.is_not_countable
                              ? total + 0
                              : total +
                                Number(
                                  calculateGradePoint(
                                    ((Number(i?.marks) +
                                      Number(i?.grace_marks)) /
                                      Number(i?.max_marks)) *
                                      Number(i?.credits)
                                  )
                                ),
                          0
                        ) +
                          Number(
                            combinedDataPrev
                              ?.filter(
                                (s) =>
                                  s.subject !=
                                  combinedData?.find(
                                    (s1) => s1.course_name == s.subject
                                  )?.course_name
                              )
                              ?.reduce(
                                (acc, it) =>
                                  acc +
                                  Number(
                                    calculateGradePoint(
                                      ((Number(it?.e_marks) +
                                        Number(it?.e_grace_marks) +
                                        Number(it?.i_marks)) /
                                        (Number(it?.e_max) +
                                          Number(it?.i_max))) *
                                        Number(it?.credit)
                                    )
                                  ),
                                0
                              )
                          )) /
                        (totalcredits +
                          Number(
                            combinedDataPrev
                              ?.filter(
                                (s) =>
                                  s.subject !=
                                  combinedData?.find(
                                    (s1) => s1.course_name == s.subject
                                  )?.course_name
                              )
                              ?.reduce((acc, it) => acc + Number(it?.credit), 0)
                          ))
                      ).toFixed(2)}
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          </tbody>
        </table>

        <div className="text-center mt-4 d-none d-print-block">
          <h6>Nomenclature / Abbreviations</h6>
        </div>
        <div className="d-flex justify-content-around px-5 ">
          <h6 className="d-none d-print-block "> P {"->"} Pass</h6>
          <h6 className="d-none d-print-block "> F {"->"} Fail</h6>
          <h6 className="d-none d-print-block "> A {"->"} Absent</h6>
          <h6 className="d-none d-print-block "> E {"->"} Exempted</h6>
          <h6 className="d-none d-print-block "> NE {"->"} Not Eligible</h6>
        </div>
      </div>
      {examData?.department != "Post Basic B.Sc. Nursing" ? (
        <>
          <p className="datere d-none d-print-block">{formattedDate}</p>
          <img
            src="/assets/images/signature/coe sign.png"
            alt=""
            className="coesignre d-none d-print-block"
            width={180}
          />
        </>
      ) : (
        <>
          <p className="daterep d-none d-print-block">{formattedDate}</p>
          <img
            src="/assets/images/signature/coe sign.png"
            alt=""
            className="coesignrep d-none d-print-block"
            width={180}
          />
        </>
      )}
    </div>
  );
};

const MarksSheetIndividual1 = ({ examResults, prevExamData, setLoading }) => {
  const { examData } = useOutletContext();
  if (examResults[0].student_id == "SUK220404ED056") {
    console.log(examResults, prevExamData);
  }
  const [cnt, setCnt] = useState(2);
  const totalcredits = useMemo(() => {
    return examResults?.reduce(
      (total, i) => total + Number(i?.credits || 0),
      0
    );
  }, [examResults]);

  const totalsum_max = useMemo(() => {
    return examResults?.reduce(
      (total, i) => total + Number(i?.max_marks || 0),
      0
    );
  }, [examResults]);

  const totalsum_min = useMemo(() => {
    return examResults?.reduce(
      (total, i) => total + Number(i?.min_marks || 0),
      0
    );
  }, [examResults]);

  const totalsum_obt = useMemo(() => {
    return examResults?.reduce(
      (total, i) => total + Number(i?.marks || 0) + Number(i?.grace_marks || 0),
      0
    );
  }, [examResults]);

  const totalabsent = useMemo(() => {
    return examResults?.reduce((total, i) => total + Number(i?.absent || 0), 0);
  }, [examResults]);

  const combinedData = useMemo(() => {
    return Object.values(
      examResults.reduce((acc, curr) => {
        const course_name = curr.course_name;
        if (!acc[course_name]) {
          acc[course_name] = { ...curr };
          acc[course_name].max_marks = Number(acc[course_name].max_marks);
          acc[course_name].min_marks = Number(acc[course_name].min_marks);
        } else {
          acc[course_name].marks += curr.marks;
          acc[course_name].max_marks += curr.max_marks;
          acc[course_name].min_marks += curr.min_marks;
          acc[course_name].grace_marks += Number(curr.grace_marks || 0);
          acc[course_name].credits += Number(curr.credits || 0);
        }
        return acc;
      }, {})
    );
  }, [examResults]);

  const combinedDataPrev = useMemo(() => {
    const result2 =
      Object.values(
        prevExamData?.reduce((acc, curr) => {
          const subject_name = curr?.subject;
          if (!acc[subject_name]) {
            acc[subject_name] = { ...curr };
            acc[subject_name].i_max = Number(acc[subject_name].i_max);
            acc[subject_name].e_max = Number(acc[subject_name].e_max);
            acc[subject_name].e_min = Number(acc[subject_name].e_min);
            acc[subject_name].i_min = Number(acc[subject_name].i_min);
            acc[subject_name].e_marks = Number(acc[subject_name].e_marks);
            acc[subject_name].i_marks = Number(acc[subject_name].i_marks);
          } else {
            acc[subject_name].e_marks += Number(curr.e_marks);
            acc[subject_name].e_max += Number(curr.e_max);
            acc[subject_name].e_min += Number(curr.e_min);
            acc[subject_name].i_marks += Number(curr.i_marks);
            acc[subject_name].i_max += Number(curr.i_max);
            acc[subject_name].i_min += Number(curr.i_min);
            acc[subject_name].e_grace_marks += Number(curr.e_grace_marks);
          }
          return acc;
        }, {})
      ) || [];

    return result2;
  }, [prevExamData]);

  const [isPass, setIsPass] = useState(0);

  const checkCondition = (data) => {
    return Number(data) ? Number(data) : "-";
  };

  useEffect(() => {
    var flag = 0;
    for (const item of examResults) {
      // console.log("result item", item);
      if (item.absent) {
        flag = 1;
        break;
      }
      if (item.min_marks > Number(item.grace_marks) + item.marks) {
        flag = 1;
        break;
      }
    }
    if (!flag) {
      setIsPass(1);
    } else {
      setIsPass(0);
    }
  }, [examResults]);

  return (
    <div className="col-md-12 bg2">
      {/* <img
                                    src={img}
                                    alt="Your Image"
                                    className="background-image"
                                  /> */}
      <div className="res2">
        <table className="table table-bordered text-center result overlay-table">
          <thead className=" ">
            <tr>
              <th>Academic Year</th>
              <th>Month and year of Examination</th>
              <th>Year/Sem</th>
              <th>Enrollment Number</th>
            </tr>
            <tr>
              <td>
                {sessionOpt.find((s) => s?.id == examData?.session_id)?.name}{" "}
              </td>
              <td>
                {months[Number(examData?.month) - 1]}-{examData?.year || "2023"}
              </td>
              <td>
                {examData?.college_id == 1111001 ||
                examData?.college_id == 1111007
                  ? examData?.class
                  : examData?.semester}
              </td>
              <td>{examResults[0]?.student_id}</td>
            </tr>
            <tr>
              <th>Faculty Name</th>
              <th colSpan="2">College Name</th>
              <th> Attempts</th>
            </tr>
            <tr>
              <td>{examData?.college}</td>

              <td colSpan="2">
                {
                  facultyData.find((item) => item.id == examData?.college_id)
                    ?.colloge_name
                }
              </td>

              <th>
                {cnt}
                {cnt == 1 ? "st" : cnt == 2 ? "nd" : cnt == 3 ? "rd" : "th"}
              </th>
            </tr>

            {examResults[0]?.program != "DOCTORATE" ? (
              <>
                <tr>
                  <th>Program</th>
                  <td>{examData?.department}</td>
                  <th>Student Name</th>
                  <td>{examResults[0]?.name}</td>
                </tr>
              </>
            ) : null}
          </thead>
        </table>
        {/* <table className="table table-bordered result ">
          <thead>
            <tr>
              <th>Enrollment Number</th>
              <td>{examResults?.[0]?.student_id}</td>
              <th>Student Name </th>
              <td>{examResults?.[0]?.name}</td>
            </tr>
            <tr>
              <th>Faculty Name</th>
              <td>{examData?.college}</td>

              <th>Program</th>
              <td>{examData?.department}</td>
            </tr>
            <tr>
              <th>College Name</th>
              <td>
                {
                  facultyData.find((item) => item.id == examData?.college_id)
                    ?.colloge_name
                }
              </td>

              <th>
                {examData?.department == "B.H.M.S."
                  ? "Year"
                  : examData?.department == "B.Sc. Nursing"
                  ? "Year"
                  : examData?.department == "Post Basic B.Sc. Nursing"
                  ? "Year"
                  : "Semester"}
              </th>

              <td>
                {" "}
                {examData?.department == "B.H.M.S."
                  ? "1st Year"
                  : examData?.department == "B.Sc. Nursing"
                  ? "1st Year"
                  : examData?.department == "Post Basic B.Sc. Nursing"
                  ? "1st Year"
                  : "2"}{" "}
              </td>
            </tr>
            <tr>
              <th>No of Attempts</th>
              <th>
                {cnt}
                {cnt == 1 ? "st" : cnt == 2 ? "nd" : cnt == 3 ? "rd" : "th"}
              </th>
            </tr>
          </thead>
        </table> */}

        <table className="table text-center result  table-bordered ">
          <thead>
            <tr>
              <th rowSpan="2" className="text-center">
                Subject Code
              </th>
              <th rowSpan="2" className="text-center">
                Subject Name
              </th>

              {totalcredits != 0 || totalcredits == null ? (
                <th rowSpan="2" className="text-center">
                  Credit
                </th>
              ) : (
                ""
              )}

              <th className="text-center" colSpan="3">
                {" "}
                {examData?.department == "B.H.M.S." ? (
                  "University"
                ) : (
                  <>
                    <span> University Exam</span>
                    <br />
                    <span>Internal Evaluation</span>
                  </>
                )}
              </th>

              {totalcredits != 0 ? (
                <th rowSpan="2" className="text-center">
                  {" "}
                  Total
                </th>
              ) : (
                ""
              )}

              {totalcredits != 0 ? (
                <th rowSpan="2" className="text-center">
                  {" "}
                  Grade
                </th>
              ) : (
                ""
              )}
              {totalcredits != 0 ? (
                <th rowSpan="2" className="text-center">
                  {" "}
                  GP
                </th>
              ) : (
                ""
              )}
              {totalcredits != 0 ? (
                <th rowSpan="2" className="text-center">
                  {" "}
                  CGP
                </th>
              ) : (
                ""
              )}
              <th rowSpan="2" className="text-center">
                {" "}
                Result
              </th>
            </tr>
            <tr>
              <th className="text-center">Max</th>
              <th className="text-center">Min</th>
              <th className="text-center">Obtained</th>
            </tr>
          </thead>
          <tbody>
            {combinedData &&
              combinedData?.map((i, key) => (
                <tr key={key}>
                  <td className="text-center">{i?.sub_code}</td>
                  <td className="text-center">{i?.course_name}</td>
                  {totalcredits != 0 ? (
                    <td className="text-center">{i?.credits || 0} </td>
                  ) : (
                    ""
                  )}

                  <td className="text-center">
                    {i?.max_marks == 0 ? "-" : i?.max_marks}
                    <br />
                    {checkCondition(
                      combinedDataPrev?.find((s) => s.subject == i?.course_name)
                        ?.i_max
                    )}
                  </td>

                  <td className="text-center">
                    {i?.min_marks == 0 ? "-" : i?.min_marks}
                    <br />
                    {checkCondition(
                      combinedDataPrev?.find((s) => s.subject == i?.course_name)
                        ?.i_min
                    )}
                  </td>

                  <td className="text-center">
                    {i?.absent == 1 ? (
                      <span className="text-danger">
                        <b>A</b>
                      </span>
                    ) : i?.marks == 0 ? (
                      "-"
                    ) : i?.marks == null ? (
                      "-"
                    ) : i?.marks == "" ? (
                      "-"
                    ) : i?.grace_marks == null || i?.grace_marks == 0 ? (
                      i?.marks
                    ) : (
                      i?.marks + "+" + i?.grace_marks
                    )}{" "}
                    <br />
                    {checkCondition(
                      combinedDataPrev?.find((s) => s.subject == i?.course_name)
                        ?.i_marks
                    )}
                  </td>

                  {/* Total */}
                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {Number(i?.marks) +
                        Number(i?.grace_marks) +
                        Number(
                          combinedDataPrev?.find(
                            (s) => s.subject == i?.course_name
                          )?.i_marks
                        )}
                    </td>
                  ) : (
                    ""
                  )}

                  {/* Grade */}
                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {calculateGrade(
                        ((Number(i?.marks) +
                          Number(i?.grace_marks) +
                          Number(
                            combinedDataPrev?.find(
                              (s) => s.subject == i?.course_name
                            )?.i_marks
                          )) /
                          (Number(i?.max_marks) +
                            Number(
                              combinedDataPrev?.find(
                                (s) => s.subject == i?.course_name
                              )?.i_max
                            ))) *
                          100
                      )}
                    </td>
                  ) : (
                    ""
                  )}

                  {/* GP */}
                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {calculateGradePoint(
                        (Number(i?.marks) +
                          Number(i?.grace_marks) +
                          Number(
                            combinedDataPrev?.filter(
                              (s) => s.subject == i?.course_name
                            )[0]?.i_marks
                          )) /
                          (Number(i?.max_marks) +
                            Number(
                              combinedDataPrev?.filter(
                                (s) => s.subject == i?.course_name
                              )[0]?.i_max
                            ))
                      )}{" "}
                    </td>
                  ) : (
                    ""
                  )}

                  {/* CGP */}
                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {Number(
                        calculateGradePoint(
                          (Number(i?.marks) +
                            Number(i?.grace_marks) +
                            Number(
                              combinedDataPrev?.filter(
                                (s) => s.subject == i?.course_name
                              )[0]?.i_marks
                            )) /
                            (Number(i?.max_marks) +
                              Number(
                                combinedDataPrev?.filter(
                                  (s) => s.subject == i?.course_name
                                )[0]?.i_max
                              ))
                        ) * Number(i?.credits)
                      ).toFixed(2)}{" "}
                    </td>
                  ) : (
                    ""
                  )}

                  <td className="text-center">
                    {i?.data?.result}{" "}
                    {Number(i?.marks) + Number(i?.grace_marks) >=
                      i?.min_marks || i?.marks == null ? (
                      <p className="text-success">
                        <b> P </b>{" "}
                      </p>
                    ) : Number(i?.marks) + Number(i?.grace_marks) >=
                        i?.min_marks || i?.marks == null ? (
                      <p className="text-success">
                        <b> P </b>{" "}
                      </p>
                    ) : (
                      <p className="text-danger">
                        <b>F </b>
                      </p>
                    )}{" "}
                  </td>
                </tr>
              ))}

            {/* Combined Previous Data */}
            {combinedDataPrev &&
              combinedDataPrev
                ?.filter(
                  (s) =>
                    s.subject !=
                    combinedData?.find((s1) => s1.course_name == s.subject)
                      ?.course_name
                )
                ?.map((i, key) => (
                  <tr key={key}>
                    <td className="text-center"> {i?.sub_code}</td>
                    <td className="text-center">{i?.subject}</td>
                    {totalcredits != 0 ? (
                      <td className="text-center">{i?.credit} </td>
                    ) : (
                      ""
                    )}

                    <td className="text-center">
                      {checkCondition(i?.e_max)}
                      <br />
                      {checkCondition(i?.i_max)}
                    </td>

                    <td className="text-center">
                      {checkCondition(i?.e_min)}
                      <br />
                      {checkCondition(i?.i_min)}
                    </td>

                    <td className="text-center">
                      {checkCondition(i?.e_marks)}
                      <br />
                      {checkCondition(i?.i_marks)}
                    </td>

                    <td className="text-center">
                      {Number(i?.e_marks) + Number(i?.i_marks)}
                    </td>

                    {/* Grade  */}
                    <td className="text-center">
                      {calculateGrade(
                        ((Number(i?.i_marks) +
                          Number(i?.e_marks) +
                          Number(i?.e_grace_marks)) /
                          (Number(i?.e_max) + Number(i?.i_max))) *
                          100
                      )}
                    </td>

                    {/* GP  */}
                    <td className="text-center">
                      {calculateGradePoint(
                        (Number(i?.i_marks) +
                          Number(i?.e_grace_marks) +
                          Number(i?.e_marks)) /
                          (Number(i?.e_max) + Number(i?.i_max))
                      )}
                    </td>

                    {/* CGP  */}
                    <td className="text-center">
                      {Number(
                        calculateGradePoint(
                          ((Number(i?.i_marks) +
                            Number(i?.e_grace_marks) +
                            Number(i?.e_marks)) /
                            (Number(i?.e_max) + Number(i?.i_max))) *
                            Number(i?.credit)
                        )
                      ).toFixed(2)}{" "}
                    </td>

                    <td className="text-center">
                      {Number(i?.i_marks) +
                        Number(i?.e_grace_marks) +
                        Number(i?.e_marks) >=
                        i?.i_min + i?.e_min || i?.marks == null ? (
                        <p className="text-success">
                          <b> P </b>{" "}
                        </p>
                      ) : (
                        <p className="text-danger">
                          <b>F </b>
                        </p>
                      )}{" "}
                    </td>
                  </tr>
                ))}

            <tr>
              <th className="text-center">Total</th>
              {totalcredits != 0 ? <th></th> : ""}
              {totalcredits != 0 ? (
                <td className="text-center">
                  {" "}
                  {totalcredits +
                    Number(
                      combinedDataPrev
                        ?.filter(
                          (s) =>
                            s.subject !=
                              combinedData?.find(
                                (s1) => s1.course_name == s.subject
                              )?.course_name && !s.is_not_countable
                        )
                        ?.reduce((acc, it) => acc + it?.credit, 0)
                    )}
                </td>
              ) : (
                ""
              )}

              {/* Total Max Here  */}
              <td className="text-center">
                {totalsum_max +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                          combinedData?.find(
                            (s1) => s1.course_name == s.subject
                          )?.course_name && !s.is_not_countable
                    )
                    ?.reduce((acc, it) => acc + Number(it.e_max), 0)}
                <br />
                {combinedDataPrev
                  ?.filter(
                    (s) =>
                      s.subject ==
                        combinedData?.find((s1) => s1.course_name == s.subject)
                          ?.course_name && !s.is_not_countable
                  )
                  ?.reduce((acc, it) => acc + Number(it.i_max), 0) +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                          combinedData?.find(
                            (s1) => s1.course_name == s.subject
                          )?.course_name && !s.is_not_countable
                    )
                    ?.reduce((acc, it) => acc + Number(it.i_max), 0)}{" "}
              </td>

              {/* Total Min Here  */}

              <td className="text-center">
                {totalsum_min +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                          combinedData?.find(
                            (s1) => s1.course_name == s.subject
                          )?.course_name && !s.is_not_countable
                    )
                    ?.reduce((acc, it) => acc + Number(it.e_min), 0)}
                <br />
                {combinedDataPrev
                  ?.filter(
                    (s) =>
                      s.subject ==
                        combinedData?.find((s1) => s1.course_name == s.subject)
                          ?.course_name && !s.is_not_countable
                  )
                  ?.reduce((acc, it) => acc + Number(it.i_min), 0) +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                          combinedData?.find(
                            (s1) => s1.course_name == s.subject
                          )?.course_name && !s.is_not_countable
                    )
                    ?.reduce((acc, it) => acc + Number(it.i_min), 0)}
              </td>

              {/* Total Obtained Here  */}
              <th className="text-center">
                {totalsum_obt +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                          combinedData?.find(
                            (s1) => s1.course_name == s.subject
                          )?.course_name && !s.is_not_countable
                    )
                    ?.reduce((acc, it) => acc + Number(it.e_marks), 0)}
                <br />
                {combinedDataPrev
                  ?.filter(
                    (s) =>
                      s.subject ==
                        combinedData?.find((s1) => s1.course_name == s.subject)
                          ?.course_name && !s.is_not_countable
                  )
                  ?.reduce((acc, it) => acc + Number(it.i_marks), 0) +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                          combinedData?.find(
                            (s1) => s1.course_name == s.subject
                          )?.course_name && !s.is_not_countable
                    )
                    ?.reduce((acc, it) => acc + Number(it.i_marks), 0)}
              </th>

              {/* Total Marks Here  */}
              <th className="text-center">
                {totalsum_obt +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                          combinedData?.find(
                            (s1) => s1.course_name == s.subject
                          )?.course_name && !s.is_not_countable
                    )
                    ?.reduce((acc, it) => acc + Number(it.e_marks), 0) +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject ==
                          combinedData?.find(
                            (s1) => s1.course_name == s.subject
                          )?.course_name && !s.is_not_countable
                    )
                    ?.reduce((acc, it) => acc + Number(it?.i_marks), 0) +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                          combinedData?.find(
                            (s1) => s1.course_name == s.subject
                          )?.course_name && !s.is_not_countable
                    )
                    ?.reduce((acc, it) => acc + Number(it.i_marks), 0)}
              </th>

              <td></td>
              {totalcredits != 0 ? <th colSpan={1}></th> : ""}

              {/* CGP  */}
              {totalcredits != 0 ? (
                <th className="text-center">
                  {totalabsent >= 1 || !isPass
                    ? "-"
                    : (
                        Number(
                          combinedData?.reduce(
                            (total, i) =>
                              i?.is_not_countable
                                ? total + 0
                                : total +
                                  Number(
                                    calculateGradePoint(
                                      ((Number(i?.marks) +
                                        Number(i?.grace_marks) +
                                        Number(
                                          combinedDataPrev?.filter(
                                            (s) => s.subject == i?.course_name
                                          )[0]?.i_marks
                                        )) /
                                        (Number(i?.max_marks) +
                                          Number(
                                            combinedDataPrev?.filter(
                                              (s) => s.subject == i?.course_name
                                            )[0]?.i_max
                                          ))) *
                                        Number(i?.credits)
                                    )
                                  ),
                            0
                          )
                        ) +
                        Number(
                          combinedDataPrev
                            ?.filter(
                              (s) =>
                                s.subject !=
                                  combinedData?.find(
                                    (s1) => s1.course_name == s.subject
                                  )?.course_name && !s.is_not_countable
                            )
                            ?.reduce(
                              (total, i) =>
                                i?.is_not_countable
                                  ? total + 0
                                  : total +
                                    Number(
                                      calculateGradePoint(
                                        ((Number(i?.e_marks) +
                                          Number(i?.e_grace_marks) +
                                          Number(i?.i_marks)) /
                                          (Number(i?.i_max) +
                                            Number(i?.e_max))) *
                                          Number(i?.credit)
                                      )
                                    ),
                              0
                            )
                        )
                      ).toFixed(2)}
                </th>
              ) : (
                ""
              )}
              {totalcredits != 0 ? <th></th> : ""}
            </tr>
            <tr className="text-center">
              <th className="text-center"> Result </th>
              <td colSpan={5} className="text-center">
                {totalabsent >= 1 ||
                examResults?.reduce(
                  (total, i) =>
                    total +
                    (calculateGradePoint(
                      (Number(i?.marks) + Number(i?.grace_marks)) /
                        Number(i?.max)
                    ) <
                      3.6),
                  0
                ) ||
                !isPass ? (
                  <span className="text-danger">
                    <b> FAIL </b>{" "}
                  </span>
                ) : (
                  <span className="text-success">
                    <b>PASS </b>
                  </span>
                )}
              </td>

              <th>SGPA </th>

              <td colSpan={4} className="text-center">
                {totalabsent?.absent >= 1 ||
                !isPass ||
                examResults?.reduce(
                  (total, i) =>
                    i?.is_not_countable
                      ? total + 0
                      : total +
                        (calculateGradePoint(
                          (Number(i?.i_marks) +
                            Number(i?.e_marks) +
                            Number(i?.e_grace)) /
                            (Number(i?.e_max) + Number(i?.i_max))
                        ) <
                          3.6),
                  0
                )
                  ? "-"
                  : totalabsent?.absent >= 1
                  ? "-"
                  : (
                      (Number(
                        combinedData?.reduce(
                          (total, i) =>
                            i?.is_not_countable
                              ? total + 0
                              : total +
                                Number(
                                  calculateGradePoint(
                                    ((Number(i?.marks) +
                                      Number(i?.grace_marks) +
                                      Number(
                                        combinedDataPrev?.filter(
                                          (s) => s.subject == i?.course_name
                                        )[0]?.i_marks
                                      )) /
                                      (Number(i?.max_marks) +
                                        Number(
                                          combinedDataPrev?.filter(
                                            (s) => s.subject == i?.course_name
                                          )[0]?.i_max
                                        ))) *
                                      Number(i?.credits)
                                  )
                                ),
                          0
                        )
                      ) +
                        Number(
                          combinedDataPrev
                            ?.filter(
                              (s) =>
                                s.subject !=
                                combinedData?.find(
                                  (s1) => s1.course_name == s.subject
                                )?.course_name
                            )
                            ?.reduce(
                              (total, i) =>
                                i?.is_not_countable
                                  ? total + 0
                                  : total +
                                    Number(
                                      calculateGradePoint(
                                        ((Number(i?.e_marks) +
                                          Number(i?.e_grace_marks) +
                                          Number(i?.i_marks)) /
                                          (Number(i?.i_max) +
                                            Number(i?.e_max))) *
                                          Number(i?.credit)
                                      )
                                    ),
                              0
                            )
                        )) /
                      (totalcredits +
                        Number(
                          combinedDataPrev
                            ?.filter(
                              (s) =>
                                s.subject !=
                                  combinedData?.find(
                                    (s1) => s1.course_name == s.subject
                                  )?.course_name && !s.is_not_countable
                            )
                            ?.reduce((acc, it) => acc + Number(it?.credit), 0)
                        ))
                    ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="text-center mt-4 d-none d-print-block">
          <h6>Nomenclature / Abbreviations</h6>
        </div>
        <div className="d-flex justify-content-around px-5 ">
          <h6 className="d-none d-print-block "> P {"->"} Pass</h6>
          <h6 className="d-none d-print-block "> F {"->"} Fail</h6>
          <h6 className="d-none d-print-block "> A {"->"} Absent</h6>
          <h6 className="d-none d-print-block "> E {"->"} Exempted</h6>
          <h6 className="d-none d-print-block "> NE {"->"} Not Eligible</h6>
        </div>
      </div>
      {examData?.department != "Post Basic B.Sc. Nursing" ? (
        <>
          <p className="datere d-none d-print-block">{formattedDate}</p>
          <img
            src="/assets/images/signature/coe sign.png"
            alt=""
            className="coesignre d-none d-print-block"
            width={180}
          />
        </>
      ) : null}
    </div>
  );
};

const MarksSheetIndividual2 = ({ examResults, prevExamData, setLoading }) => {
  const { examData } = useOutletContext();

  const [cnt, setCnt] = useState(2);
  const totalcredits = useMemo(() => {
    return examResults?.reduce(
      (total, i) => total + Number(i?.credits || 0),
      0
    );
  }, [examResults]);

  const totalsum_max = useMemo(() => {
    return examResults?.reduce(
      (total, i) => total + Number(i?.max_marks || 0),
      0
    );
  }, [examResults]);

  const totalsum_min = useMemo(() => {
    return examResults?.reduce(
      (total, i) => total + Number(i?.min_marks || 0),
      0
    );
  }, [examResults]);

  const totalsum_obt = useMemo(() => {
    return examResults?.reduce(
      (total, i) => total + Number(i?.marks || 0) + Number(i?.grace_marks || 0),
      0
    );
  }, [examResults]);

  const totalabsent = useMemo(() => {
    return examResults?.reduce((total, i) => total + Number(i?.absent || 0), 0);
  }, [examResults]);

  const combinedData = useMemo(() => {
    return Object.values(
      examResults.reduce((acc, curr) => {
        const course_name = curr.course_name;
        if (!acc[course_name]) {
          acc[course_name] = { ...curr };
          acc[course_name].max_marks = Number(acc[course_name].max_marks);
          acc[course_name].min_marks = Number(acc[course_name].min_marks);
        } else {
          acc[course_name].marks += curr.marks;
          acc[course_name].max_marks += curr.max_marks;
          acc[course_name].min_marks += curr.min_marks;
          acc[course_name].grace_marks += Number(curr.grace_marks || 0);
          acc[course_name].credits += Number(curr.credits || 0);
        }
        return acc;
      }, {})
    );
  }, [examResults]);

  const combinedDataPrev = useMemo(() => {
    const result2 =
      Object.values(
        prevExamData?.reduce((acc, curr) => {
          const subject_name = curr?.subject;
          if (!acc[subject_name]) {
            acc[subject_name] = { ...curr };
            acc[subject_name].i_max = Number(acc[subject_name].i_max);
            acc[subject_name].e_max = Number(acc[subject_name].e_max);
            acc[subject_name].e_min = Number(acc[subject_name].e_min);
            acc[subject_name].i_min = Number(acc[subject_name].i_min);
            acc[subject_name].e_marks = Number(acc[subject_name].e_marks);
            acc[subject_name].i_marks = Number(acc[subject_name].i_marks);
          } else {
            acc[subject_name].e_marks += Number(curr.e_marks);
            acc[subject_name].e_max += Number(curr.e_max);
            acc[subject_name].e_min += Number(curr.e_min);
            acc[subject_name].i_marks += Number(curr.i_marks);
            acc[subject_name].i_max += Number(curr.i_max);
            acc[subject_name].i_min += Number(curr.i_min);
            acc[subject_name].e_grace_marks += Number(curr.e_grace_marks);
          }
          return acc;
        }, {})
      ) || [];

    return result2;
  }, [prevExamData]);

  const [isPass, setIsPass] = useState(0);

  const checkCondition = (data) => {
    return Number(data) ? Number(data) : "-";
  };

  useEffect(() => {
    var flag = 0;
    for (const item of examResults) {
      // console.log("result item", item);
      if (item.absent) {
        flag = 1;
        break;
      }
      if (item.min_marks > Number(item.grace_marks) + item.marks) {
        flag = 1;
        break;
      }
    }
    if (!flag) {
      setIsPass(1);
    } else {
      setIsPass(0);
    }
  }, [examResults]);

  return (
    <div className="col-md-12 bg2">
      {/* <img
                                    src={img}
                                    alt="Your Image"
                                    className="background-image"
                                  /> */}
      <div className="res2">
        <table className="table table-bordered text-center result overlay-table">
          <thead className=" ">
            <tr>
              <th>Academic Year</th>
              <th>Month and year of Examination</th>
              <th>Year/Sem</th>
              <th>Enrollment Number</th>
            </tr>
            <tr>
              <td>
                {sessionOpt.find((s) => s?.id == examData?.session_id)?.name}{" "}
              </td>
              <td>
                {months[Number(examData?.month) - 1]}-{examData?.year || "2023"}
              </td>
              <td>
                {examData?.college_id == 1111001 ||
                examData?.college_id == 1111007
                  ? examData?.class
                  : examData?.semester}
              </td>
              <td>{examResults[0]?.student_id}</td>
            </tr>
            <tr>
              <th>Faculty Name</th>
              <th colSpan="2">College Name</th>
              <th> Attempts</th>
            </tr>
            <tr>
              <td>{examData?.college}</td>

              <td colSpan="2">
                {
                  facultyData.find((item) => item.id == examData?.college_id)
                    ?.colloge_name
                }
              </td>

              <th>
                {cnt}
                {cnt == 1 ? "st" : cnt == 2 ? "nd" : cnt == 3 ? "rd" : "th"}
              </th>
            </tr>

            {examResults[0]?.program != "DOCTORATE" ? (
              <>
                <tr>
                  <th>Program</th>
                  <td>{examData?.department}</td>
                  <th>Student Name</th>
                  <td>{examResults[0]?.name}</td>
                </tr>
              </>
            ) : null}
          </thead>
        </table>
        {/* <table className="table table-bordered result ">
          <thead>
            <tr>
              <th>Enrollment Number</th>
              <td>{examResults?.[0]?.student_id}</td>
              <th>Student Name </th>
              <td>{examResults?.[0]?.name}</td>
            </tr>
            <tr>
              <th>Faculty Name</th>
              <td>{examData?.college}</td>

              <th>Program</th>
              <td>{examData?.department}</td>
            </tr>
            <tr>
              <th>College Name</th>
              <td>
                {
                  facultyData.find((item) => item.id == examData?.college_id)
                    ?.colloge_name
                }
              </td>

              <th>
                {examData?.department == "B.H.M.S."
                  ? "Year"
                  : examData?.department == "B.Sc. Nursing"
                  ? "Year"
                  : examData?.department == "Post Basic B.Sc. Nursing"
                  ? "Year"
                  : "Semester"}
              </th>

              <td>
                {" "}
                {examData?.department == "B.H.M.S."
                  ? "1st Year"
                  : examData?.department == "B.Sc. Nursing"
                  ? "1st Year"
                  : examData?.department == "Post Basic B.Sc. Nursing"
                  ? "1st Year"
                  : "2"}{" "}
              </td>
            </tr>
            <tr>
              <th>No of Attempts</th>
              <th>
                {cnt}
                {cnt == 1 ? "st" : cnt == 2 ? "nd" : cnt == 3 ? "rd" : "th"}
              </th>
            </tr>
          </thead>
        </table> */}

        <table className="table text-center result table-bordered ">
          <thead>
            <tr>
              <th rowSpan="2" className="text-center">
                Subject Code
              </th>
              <th rowSpan="2" className="text-center">
                Subject Name
              </th>

              {totalcredits != 0 || totalcredits == null ? (
                <th rowSpan="2" className="text-center">
                  Credit
                </th>
              ) : (
                ""
              )}

              <th className="text-center" colSpan="3">
                {" "}
                {examData?.department == "B.H.M.S." ? (
                  "University"
                ) : (
                  <>
                    <span>
                      {" "}
                      University Exam <br />
                      {examData?.department_id == 171 ||
                      examData?.department_id == 172 ||
                      examData?.department_id == 173
                        ? "Internal Evaluation"
                        : null}{" "}
                    </span>
                  </>
                )}
              </th>

              {totalcredits != 0 ? (
                <th rowSpan="" className="text-center">
                  {" "}
                  Total
                </th>
              ) : (
                ""
              )}

              {totalcredits != 0 ? (
                <th rowSpan="2" className="text-center">
                  {" "}
                  Grade
                </th>
              ) : (
                ""
              )}
              {totalcredits != 0 ? (
                <th rowSpan="2" className="text-center">
                  {" "}
                  GP
                </th>
              ) : (
                ""
              )}
              {totalcredits != 0 ? (
                <th rowSpan="2" className="text-center">
                  {" "}
                  CGP
                </th>
              ) : (
                ""
              )}
              <th rowSpan="2" className="text-center">
                {" "}
                Result
              </th>
            </tr>
            <tr>
              <th className="text-center">Max</th>
              <th className="text-center">Min</th>
              <th className="text-center">Obtained</th>
            </tr>
          </thead>
          <tbody>
            {combinedData &&
              combinedData?.map((i, key) => (
                <tr key={key}>
                  <td className="text-center">{i?.sub_code}</td>
                  <td className="text-center">{i?.course_name}</td>
                  {totalcredits != 0 ? (
                    <td className="text-center">{i?.credits || 0} </td>
                  ) : (
                    ""
                  )}

                  <td className="text-center">
                    {i?.max_marks == 0 ? "-" : i?.max_marks}
                    <br />
                    {
                      combinedDataPrev?.filter(
                        (s) => s.subject == i?.course_name
                      )[0]?.i_max
                    }
                  </td>

                  <td className="text-center">
                    {i?.min_marks == 0 ? "-" : i?.min_marks}
                    <br />
                    {
                      combinedDataPrev?.filter(
                        (s) => s.subject == i?.course_name
                      )[0]?.i_min
                    }
                  </td>

                  <td className="text-center">
                    {i?.absent == 1 ? (
                      <span className="text-danger">
                        <b>A</b>
                      </span>
                    ) : i?.marks == 0 ? (
                      "-"
                    ) : i?.marks == null ? (
                      "-"
                    ) : i?.marks == "" ? (
                      "-"
                    ) : i?.grace_marks == null || i?.grace_marks == 0 ? (
                      i?.marks
                    ) : (
                      i?.marks + "+" + i?.grace_marks
                    )}{" "}
                    <br />
                    {
                      combinedDataPrev?.filter(
                        (s) => s.subject == i?.course_name
                      )[0]?.i_marks
                    }
                  </td>

                  {/* Total */}
                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {Number(i?.marks) +
                        Number(i?.grace_marks) +
                        combinedDataPrev?.filter(
                          (s) => s.subject == i?.course_name
                        )[0]?.i_marks}
                    </td>
                  ) : (
                    ""
                  )}

                  {/* Grade */}
                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {calculateGrade(
                        ((Number(i?.marks) +
                          Number(i?.grace_marks) +
                          combinedDataPrev
                            ?.filter(
                              (s) =>
                                s.subject ==
                                combinedData?.find(
                                  (s1) => s1.course_name == s.subject
                                )?.course_name
                            )
                            ?.reduce(
                              (acc, it) => acc + Number(it?.i_marks),
                              0
                            )) /
                          (Number(i?.max_marks) +
                            combinedDataPrev
                              ?.filter(
                                (s) =>
                                  s.subject ==
                                  combinedData?.find(
                                    (s1) => s1.course_name == s.subject
                                  )?.course_name
                              )
                              ?.reduce(
                                (acc, it) => acc + Number(it?.i_max),
                                0
                              ))) *
                          100
                      )}
                    </td>
                  ) : (
                    ""
                  )}

                  {/* GP */}
                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {calculateGradePoint(
                        (Number(i?.marks) +
                          Number(i?.grace_marks) +
                          combinedDataPrev
                            ?.filter(
                              (s) =>
                                s.subject ==
                                combinedData?.find(
                                  (s1) => s1.course_name == s.subject
                                )?.course_name
                            )
                            ?.reduce(
                              (acc, it) => acc + Number(it?.i_marks),
                              0
                            )) /
                          (Number(i?.max_marks) +
                            combinedDataPrev
                              ?.filter(
                                (s) =>
                                  s.subject ==
                                  combinedData?.find(
                                    (s1) => s1.course_name == s.subject
                                  )?.course_name
                              )
                              ?.reduce((acc, it) => acc + Number(it?.i_max), 0))
                      )}{" "}
                    </td>
                  ) : (
                    ""
                  )}

                  {/* CGP */}
                  {totalcredits != 0 ? (
                    <td className="text-center">
                      {calculateGradePoint(
                        ((Number(i?.marks) +
                          Number(i?.grace_marks) +
                          combinedDataPrev
                            ?.filter(
                              (s) =>
                                s.subject ==
                                combinedData?.find(
                                  (s1) => s1.course_name == s.subject
                                )?.course_name
                            )
                            ?.reduce(
                              (acc, it) => acc + Number(it?.i_marks),
                              0
                            )) /
                          (Number(i?.max_marks) +
                            combinedDataPrev
                              ?.filter(
                                (s) =>
                                  s.subject ==
                                  combinedData?.find(
                                    (s1) => s1.course_name == s.subject
                                  )?.course_name
                              )
                              ?.reduce(
                                (acc, it) => acc + Number(it?.i_max),
                                0
                              ))) *
                          Number(i?.credits).toFixed(2)
                      )}{" "}
                    </td>
                  ) : (
                    ""
                  )}

                  <td className="text-center">
                    {i?.data?.result}{" "}
                    {Number(i?.marks) + Number(i?.grace_marks) >=
                      i?.min_marks || i?.marks == null ? (
                      <p className="text-success">
                        <b> P </b>{" "}
                      </p>
                    ) : Number(i?.marks) + Number(i?.grace_marks) >=
                        i?.min_marks || i?.marks == null ? (
                      <p className="text-success">
                        <b> P </b>{" "}
                      </p>
                    ) : (
                      <p className="text-danger">
                        <b>F </b>
                      </p>
                    )}{" "}
                  </td>
                </tr>
              ))}
            {combinedDataPrev &&
              combinedDataPrev
                ?.filter(
                  (s) =>
                    s.subject !=
                    combinedData?.find((s1) => s1.course_name == s.subject)
                      ?.course_name
                )
                ?.map((i, key) => (
                  <tr key={key}>
                    <td className="text-center"> {i?.sub_code}</td>
                    <td className="text-center">{i?.subject}</td>
                    {totalcredits != 0 ? (
                      <td className="text-center">{i?.credit} </td>
                    ) : (
                      ""
                    )}

                    <td className="text-center">
                      {checkCondition(i?.e_max)}
                      <br />
                      {checkCondition(i?.i_max)}
                    </td>

                    <td className="text-center">
                      {checkCondition(i?.e_min)}
                      <br />
                      {checkCondition(i?.i_min)}
                    </td>

                    <td className="text-center">
                      {checkCondition(i?.e_marks)}
                      <br />
                      {checkCondition(i?.i_marks)}
                    </td>

                    <td className="text-center">
                      {Number(i?.e_marks) + Number(i?.i_marks)}
                    </td>

                    {/* Grade  */}
                    <td className="text-center">
                      {calculateGrade(
                        ((Number(i?.e_marks) +
                          Number(i?.e_grace_marks) +
                          Number(i?.i_marks)) /
                          (Number(i?.i_max) + Number(i?.e_max))) *
                          100
                      )}
                    </td>

                    {/* GP  */}
                    <td className="text-center">
                      {calculateGradePoint(
                        (Number(i?.i_marks) +
                          Number(i?.e_grace_marks) +
                          Number(i?.e_marks)) /
                          (Number(i?.e_max) + Number(i?.i_max))
                      )}
                    </td>

                    {/* CGP  */}
                    <td className="text-center">
                      {calculateGradePoint(
                        ((Number(i?.i_marks) +
                          Number(i?.e_grace_marks) +
                          Number(i?.e_marks)) /
                          (Number(i?.e_max) + Number(i?.i_max))) *
                          Number(i?.credit).toFixed(2)
                      )}{" "}
                    </td>

                    <td className="text-center">
                      {Number(i?.i_marks) +
                        Number(i?.e_grace_marks) +
                        Number(i?.e_marks) >=
                        i?.i_min + i?.e_min || i?.marks == null ? (
                        <p className="text-success">
                          <b> P </b>{" "}
                        </p>
                      ) : (
                        <p className="text-danger">
                          <b>F </b>
                        </p>
                      )}{" "}
                    </td>
                  </tr>
                ))}

            <tr>
              <th className="text-center">Total</th>
              {totalcredits != 0 ? <th></th> : ""}
              {totalcredits != 0 ? (
                <td className="text-center">
                  {" "}
                  {totalcredits +
                    Number(
                      combinedDataPrev
                        ?.filter(
                          (s) =>
                            s.subject !=
                            combinedData?.find(
                              (s1) => s1.course_name == s.subject
                            )?.course_name
                        )
                        ?.reduce((acc, it) => acc + it?.credit, 0)
                    )}
                </td>
              ) : (
                ""
              )}

              {/* Total Max Here  */}
              <td className="text-center">
                {totalsum_max +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                        combinedData?.find((s1) => s1.course_name == s.subject)
                          ?.course_name
                    )
                    ?.reduce((acc, it) => acc + Number(it.e_max), 0)}
                <br />
                {combinedDataPrev
                  ?.filter(
                    (s) =>
                      s.subject !=
                      combinedData?.find((s1) => s1.course_name == s.subject)
                        ?.course_name
                  )
                  ?.reduce((acc, it) => acc + Number(it.i_max), 0)}
              </td>

              {/* Total Min Here  */}

              <td className="text-center">
                {totalsum_min +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                        combinedData?.find((s1) => s1.course_name == s.subject)
                          ?.course_name
                    )
                    ?.reduce((acc, it) => acc + Number(it.e_min), 0)}
                <br />

                {combinedDataPrev
                  ?.filter(
                    (s) =>
                      s.subject !=
                      combinedData?.find((s1) => s1.course_name == s.subject)
                        ?.course_name
                  )
                  ?.reduce((acc, it) => acc + Number(it.i_min), 0)}
              </td>

              {/* Total Obtained Here  */}
              <th className="text-center">
                {totalsum_obt +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                        combinedData?.find((s1) => s1.course_name == s.subject)
                          ?.course_name
                    )
                    ?.reduce((acc, it) => acc + Number(it.e_marks), 0)}
                <br />
                {combinedDataPrev
                  ?.filter(
                    (s) =>
                      s.subject !=
                      combinedData?.find((s1) => s1.course_name == s.subject)
                        ?.course_name
                  )
                  ?.reduce((acc, it) => acc + Number(it.i_marks), 0)}
              </th>

              {/* Total Marks Here  */}
              <th className="text-center">
                {totalsum_obt +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                        combinedData?.find((s1) => s1.course_name == s.subject)
                          ?.course_name
                    )
                    ?.reduce((acc, it) => acc + Number(it.e_marks), 0) +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject ==
                        combinedData?.find((s1) => s1.course_name == s.subject)
                          ?.course_name
                    )
                    ?.reduce((acc, it) => acc + Number(it?.i_marks), 0) +
                  combinedDataPrev
                    ?.filter(
                      (s) =>
                        s.subject !=
                        combinedData?.find((s1) => s1.course_name == s.subject)
                          ?.course_name
                    )
                    ?.reduce((acc, it) => acc + Number(it.i_marks), 0)}
              </th>

              <td></td>
              {totalcredits != 0 ? <th colSpan={1}></th> : ""}

              {/* CGP  */}
              {totalcredits != 0 ? (
                <th className="text-center">
                  {totalabsent >= 1 || !isPass
                    ? "-"
                    : (
                        examResults?.reduce(
                          (total, i) =>
                            i?.is_not_countable
                              ? total + 0
                              : total +
                                calculateGradePoint(
                                  (Number(i?.marks) + Number(i?.grace_marks)) /
                                    Number(i?.max_marks)
                                ) *
                                  Number(i?.credits),
                          0
                        ) +
                        Number(
                          combinedDataPrev
                            ?.filter(
                              (s) =>
                                s.subject ==
                                combinedData?.find(
                                  (s1) => s1.course_name == s.subject
                                )?.course_name
                            )
                            ?.reduce(
                              (total, i) =>
                                i?.is_not_countable || Number(i?.i_max) == 0
                                  ? total + 0
                                  : total +
                                    calculateGradePoint(
                                      Number(i?.i_marks) / Number(i?.i_max)
                                    ) *
                                      Number(i?.credit),
                              0
                            )
                        ) +
                        Number(
                          combinedDataPrev
                            ?.filter(
                              (s) =>
                                s.subject !=
                                combinedData?.find(
                                  (s1) => s1.course_name == s.subject
                                )?.course_name
                            )
                            ?.reduce(
                              (total, i) =>
                                i?.is_not_countable
                                  ? total + 0
                                  : total +
                                    calculateGradePoint(
                                      (Number(i?.e_marks) +
                                        Number(i?.e_grace_marks) +
                                        Number(i?.i_marks)) /
                                        (Number(i?.i_max) + Number(i?.e_max))
                                    ) *
                                      Number(i?.credit),
                              0
                            )
                        )
                      ).toFixed(2)}
                </th>
              ) : (
                ""
              )}
              {totalcredits != 0 ? <th></th> : ""}
            </tr>
            <tr className="text-center">
              <th className="text-center"> Result </th>
              <td colSpan={5} className="text-center">
                {totalabsent >= 1 ||
                examResults?.reduce(
                  (total, i) =>
                    total +
                    (calculateGradePoint(
                      (Number(i?.marks) + Number(i?.grace_marks)) /
                        Number(i?.max)
                    ) <
                      3.6),
                  0
                ) ||
                !isPass ? (
                  <span className="text-danger">
                    <b> FAIL </b>{" "}
                  </span>
                ) : (
                  <span className="text-success">
                    <b>PASS </b>
                  </span>
                )}
              </td>

              <th>SGPA</th>

              <td colSpan={4} className="text-center">
                {totalabsent?.absent >= 1 ||
                !isPass ||
                examResults?.reduce(
                  (total, i) =>
                    i?.is_not_countable
                      ? total + 0
                      : total +
                        (calculateGradePoint(
                          (Number(i?.i_marks) +
                            Number(i?.e_marks) +
                            Number(i?.e_grace)) /
                            (Number(i?.e_max) + Number(i?.i_max))
                        ) <
                          3.6),
                  0
                )
                  ? "-"
                  : totalabsent?.absent >= 1
                  ? "-"
                  : (
                      (examResults?.reduce(
                        (total, i) =>
                          i?.is_not_countable
                            ? total + 0
                            : total +
                              Number(
                                calculateGradePoint(
                                  ((Number(i?.marks) + Number(i?.grace_marks)) /
                                    Number(i?.max_marks)) *
                                    Number(i?.credits)
                                )
                              ),
                        0
                      ) +
                        Number(
                          combinedDataPrev
                            ?.filter(
                              (s) =>
                                s.subject !=
                                combinedData?.find(
                                  (s1) => s1.course_name == s.subject
                                )?.course_name
                            )
                            ?.reduce(
                              (acc, it) =>
                                acc +
                                Number(
                                  calculateGradePoint(
                                    ((Number(it?.e_marks) +
                                      Number(it?.e_grace_marks) +
                                      Number(it?.i_marks)) /
                                      (Number(it?.e_max) + Number(it?.i_max))) *
                                      Number(it?.credit)
                                  )
                                ),
                              0
                            )
                        )) /
                      (totalcredits +
                        Number(
                          combinedDataPrev
                            ?.filter(
                              (s) =>
                                s.subject !=
                                combinedData?.find(
                                  (s1) => s1.course_name == s.subject
                                )?.course_name
                            )
                            ?.reduce((acc, it) => acc + Number(it?.credit), 0)
                        ))
                    ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="text-center mt-4 d-none d-print-block">
          <h6>Nomenclature / Abbreviations</h6>
        </div>
        <div className="d-flex justify-content-around px-5 ">
          <h6 className="d-none d-print-block "> P {"->"} Pass</h6>
          <h6 className="d-none d-print-block "> F {"->"} Fail</h6>
          <h6 className="d-none d-print-block "> A {"->"} Absent</h6>
          <h6 className="d-none d-print-block "> E {"->"} Exempted</h6>
          <h6 className="d-none d-print-block "> NE {"->"} Not Eligible</h6>
        </div>
      </div>

      {examData?.department != "Post Basic B.Sc. Nursing" ? (
        <>
          <p className="datere d-none d-print-block">{formattedDate}</p>
          <img
            src="/assets/images/signature/coe sign.png"
            alt=""
            className="coesignre d-none d-print-block"
            width={180}
          />
        </>
      ) : null}
    </div>
  );
};

const MarksSheet = () => {
  const tableRef = useRef();

  const { examData } = useOutletContext();

  console.log(examData);

  const onDownload = useReactToPrint({
    content: () => tableRef.current,
  });

  const { examId } = useParams();

  const [studentSet, setStudentSet] = useState([]);
  const [prevExamData, setPrevExamData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    await Http.get(`${RE_EXAM_REPORT_DETAILED}/${examId}`)
      .then((res) => {
        setReportData(res.data.data);
        setPrevExamData(res.data.prevMarks);
        // for (const i of res.data.data) {
        //   console.log(i);
        // }

        const stdSet = new Set();
        res.data.data.forEach((item) => {
          stdSet.add(item.student_id);
        });
        setStudentSet(Array.from(stdSet));
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) return <Loader loading={true} />;
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <button
              onClick={onDownload}
              className="btn btn-success float-right mr-2"
            >
              Export
            </button>
          </div>{" "}
          <br />
          <div className="col-md-12 mt-2">
            <h6 className="card-header">Marks Sheet</h6>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="table-responsive">
              <div ref={tableRef} className="">
                {prevExamData?.length > 0 &&
                  studentSet?.map((i, key) => (
                    <div className="res">
                      <div key={key} className="result overlay-table">
                        {examData?.department == "Bachelor of Education " ||
                        examData?.college == "FACULTY OF SCIENCE" ||
                        examData?.college ==
                          "FACULTY OF COMMERCE & MANAGEMENT" ||
                        examData?.college ==
                          "FACULTY OF ARTS AND HUMANITIES" ? (
                          <MarksSheetIndividual1
                            examResults={reportData?.filter(
                              (s) => s.student_id == i
                            )}
                            prevExamData={prevExamData?.filter(
                              (s) => s.student_id == i
                            )}
                            examData={examData}
                          />
                        ) : examData?.college == "FACULTY OF LAW" ? (
                          <MarksSheetIndividual2
                            examResults={reportData?.filter(
                              (s) => s.student_id == i
                            )}
                            prevExamData={prevExamData?.filter(
                              (s) => s.student_id == i
                            )}
                            examData={examData}
                          />
                        ) : (
                          <MarksSheetIndividual
                            examResults={reportData?.filter(
                              (s) => s.student_id == i
                            )}
                            prevExamData={prevExamData?.filter(
                              (s) => s.student_id == i
                            )}
                            examData={examData}
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarksSheet;
